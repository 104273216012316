import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import AnimateCC from 'react-adobe-animate';

import { TopBar } from '../../components/TopBar';

import MapFr from '../../images/png/plan_cim_bxl_fr.png';
import MapNl from '../../images/png/plan_cim_bxl_nl.png';
import MapEn from '../../images/png/plan_cim_bxl_en.png';

import './index.scss';

type Props = {
    baseUrl?: string;
};
export const Map: FunctionComponent<Props> = ({ baseUrl = '' }) => {
    const { t } = useTranslation();
    const { language } = useParams();
    return (
        <>
            <TopBar baseUrl={baseUrl} />
            <section className={`${language} container map with-top-bar`}>
                <section className="header">
                    <div>
                        <div className="animation">
                            <AnimateCC
                                animationName="VER_ORANGE_HELLONoB"
                                composition="F8A91440FBB0C3419904307DD20938C3"
                            />
                        </div>
                        <h1>{t('map.title')}</h1>
                    </div>
                    <div className="subtitle-container">
                        <h2>{t('map.subtitle')}</h2>
                        <p>
                            {t('map.description1')}
                            <span>{t('difficulty.easy')}</span>
                            {t('map.description2')}
                            <span>{t('difficulty.hard')}</span>
                            {t('map.description3')}
                        </p>
                    </div>
                </section>
                {language === 'fr' && (
                    <img src={MapFr} className="map-container" alt="map" />
                )}
                {language === 'en' && (
                    <img src={MapEn} className="map-container" alt="map" />
                )}
                {language === 'nl' && (
                    <img src={MapNl} className="map-container" alt="map" />
                )}
                <div className="flex center">
                    <Link
                        className="button go"
                        to={`/${baseUrl}/${language}/1/easy/step_1`}
                    >
                        {t('map.next')}
                    </Link>
                </div>
            </section>
        </>
    );
};
