import React, { FunctionComponent } from 'react';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetConfig, Config } from './Config';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';

import { TopBar } from '../../components/TopBar';

import './index.scss';

type Props = {
    baseUrl?: string;
};
export const Question: FunctionComponent<Props> = ({ baseUrl = '' }) => {
    // const { t } = useTranslation();
    const { language, questionId, step } = useParams();
    const config: Config | undefined = useGetConfig(questionId);
    if (!config) return null;
    return (
        <>
            <TopBar withDifficulty baseUrl={baseUrl} />
            <section className={`${language} container question with-top-bar`}>
                {step === 'step_1' && (
                    <Step1 config={config} baseUrl={baseUrl} />
                )}
                {step === 'step_2' && (
                    <Step2 config={config} baseUrl={baseUrl} />
                )}
            </section>
        </>
    );
};
