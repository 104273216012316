import React, { FunctionComponent } from 'react';

import { LangSwitch } from '../LangSwitch';
import { DifficultySwitch } from '../DifficultySwitch';
import { HomeLink } from '../HomeLink';

import './index.scss';

type Props = {
    withDifficulty?: boolean;
    baseUrl: string;
};

export const TopBar: FunctionComponent<Props> = ({
    withDifficulty,
    baseUrl,
}) => (
    <section className="top-bar">
        {withDifficulty && <DifficultySwitch />}
        <HomeLink baseUrl={baseUrl} />
        <LangSwitch />
    </section>
);
