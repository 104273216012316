import React, { FunctionComponent } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { useOuterClick } from '../../hooks/useOuterClick';

import './index.scss';

type Props = {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
};
export const DifficultySwitch: FunctionComponent<Props> = ({
    top = '13px',
    left = '10px',
    right = 'auto',
    bottom = 'auto',
}) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { difficulty } = useParams();
    const navigate = useNavigate();
    const handleClick = (newDifficulty: string): void => {
        navigate(pathname.replace(`${difficulty}`, newDifficulty), {
            replace: true,
        });
    };
    return (
        <div style={{ top, left, right, bottom }} className="difficulty-switch">
            <div
                className={`${difficulty === 'easy' ? 'active ' : ''}button`}
                onClick={() => handleClick('easy')}
                role="button"
                tabIndex={0}
            >
                {t('difficulty.easy')}
            </div>
            <div
                className={`${difficulty === 'hard' ? 'active ' : ''}button`}
                onClick={() => handleClick('hard')}
                role="button"
                tabIndex={0}
            >
                {t('difficulty.hard')}
            </div>
        </div>
    );
};
