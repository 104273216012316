import React, { FunctionComponent } from 'react';

import { Link, useParams } from 'react-router-dom';
import HomeSvg from '../../images/svg/cim_home.svg';

import './index.scss';

type Props = {
    baseUrl: string;
};

export const HomeLink: FunctionComponent<Props> = ({ baseUrl }) => {
    const { language } = useParams();
    return (
        <Link to={`/${baseUrl}/${language}`} className="home-link">
            <img src={HomeSvg} alt="home" />
        </Link>
    );
};
