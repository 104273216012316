import React, { FunctionComponent } from 'react';
import {
    BrowserRouter,
    Routes as RrdRoutes,
    Route,
    Navigate,
} from 'react-router-dom';

import { Routes } from './components/Routes';

const App: FunctionComponent = () => (
    <div className="App">
        <BrowserRouter>
            <RrdRoutes>
                <Route
                    path="evere/:language/*"
                    element={<Routes baseUrl="evere" />}
                />
                <Route
                    path="evere/"
                    element={<Navigate replace to="/evere/fr" />}
                />
                <Route path="/" element={<Navigate replace to="/evere/fr" />} />
            </RrdRoutes>
        </BrowserRouter>
    </div>
);

export default App;
