import { CSSProperties } from 'react';

import imageStep1 from '../../images/jpg/questions/cim_photos_800x500_etape_1.jpg';
import imageStep2 from '../../images/jpg/questions/cim_photos_800x500_etape_2.jpg';
import imageStep3 from '../../images/jpg/questions/cim_photos_800x500_etape_3.jpg';
import imageStep4 from '../../images/jpg/questions/cim_photos_800x500_etape_4.jpg';
import imageRoundStep4 from '../../images/jpg/questions/cim_img_rond_400x400_etape_4_facile.png';
import imageStep5 from '../../images/jpg/questions/cim_photos_800x500_etape_5.jpg';
import imageStep6 from '../../images/jpg/questions/cim_photos_800x500_etape_6.jpg';
import imageStep7 from '../../images/jpg/questions/cim_photos_800x500_etape_7.jpg';
import imageStep8 from '../../images/jpg/questions/cim_photos_800x500_etape_8.jpg';
import imageRoundStep8 from '../../images/jpg/questions/cim_img_rond_400x400_etape_8_difficile.png';
import imageStep9 from '../../images/jpg/questions/cim_photos_800x500_etape_9.jpg';
import imageRoundStep9 from '../../images/jpg/questions/cim_img_rond_400x400_etape_9_facile.png';
import imageRoundStep9Hard from '../../images/jpg/questions/cim_img_rond_400x400_etape_9_difficile.png';
import imageStep10 from '../../images/jpg/questions/cim_photos_800x500_etape_10.jpg';
import imageRoundStep10Hard from '../../images/jpg/questions/cim_img_rond_400x400_etape_10_difficile.png';
import imageStep11 from '../../images/jpg/questions/cim_photos_800x500_etape_11.jpg';
import imageStep12 from '../../images/jpg/questions/cim_photos_800x500_etape_12.jpg';
import imageRoundStep13 from '../../images/jpg/questions/cim_img_rond_400x400_etape_13_facile.png';
import imageStep13 from '../../images/jpg/questions/cim_photos_800x500_etape_13.jpg';
import imageStep14 from '../../images/jpg/questions/cim_photos_800x500_etape_14.jpg';
import imageStep15 from '../../images/jpg/questions/cim_photos_800x500_etape_15.jpg';
import imageStep16 from '../../images/jpg/questions/cim_photos_800x500_etape_16.jpg';

export type Animation = {
    name: string;
    composition: string;
    style: CSSProperties;
};

type QuestionsInfos = {
    count: number;
    goodMulti?: Array<number>;
    good?: number;
    roundImages?: Array<string>;
    helper?: boolean;
    multi?: boolean;
};

type Questions = {
    easy: QuestionsInfos;
    hard: QuestionsInfos;
};

export type Color = 'blue' | 'orange' | 'purple' | 'yellow';

export type Config = {
    color: Color;
    animation: Animation;
    questions: Questions;
    image: string;
};

type Configs = Array<Config>;

export const configs: Configs = [
    {
        color: 'blue',
        animation: {
            name: 'VER_JAUNE_NOTES',
            composition: 'C1FEBD935D6C5343A6FAFDCFF03CE75F',
            style: {
                position: 'fixed',
                bottom: '16vh',
                left: '-2vw',
                width: '100vw',
            },
        },
        image: imageStep1,
        questions: {
            easy: {
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'purple',
        animation: {
            name: 'VER_ORANGE_BOUGIE',
            composition: 'EB330A84A5979343B2A09ABF55D06068',
            style: {
                position: 'fixed',
                width: '80vw',
                top: '60vh',
                left: '14vw',
            },
        },
        image: imageStep2,
        questions: {
            easy: {
                count: 3,
                good: 3,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'orange',
        animation: {
            name: 'VER_COLORS_PARAPLUIE',
            composition: 'F9EE8A88C185EB4CB54A421E4F319B61',
            style: {
                position: 'fixed',
                width: '90vw',
                left: '-1vw',
                top: '60vh',
            },
        },
        image: imageStep3,
        questions: {
            easy: {
                count: 3,
                good: 1,
            },
            hard: {
                count: 3,
                good: 1,
            },
        },
    },
    {
        color: 'yellow',
        animation: {
            name: 'VER_MAUVE_ROI',
            composition: '6689133310EBB143A977B47F2B70AC24',
            style: {
                position: 'fixed',
                width: '90vw',
                left: '15vw',
                top: '50vh',
            },
        },
        image: imageStep4,
        questions: {
            easy: {
                roundImages: [imageRoundStep4],
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'purple',
        animation: {
            name: 'VER_COLORSend',
            composition: '1972DF326777A847AC398010697550581',
            style: {
                width: '98vw',
                position: 'fixed',
                bottom: '-1vh',
                left: '0',
            },
        },
        image: imageStep5,
        questions: {
            easy: {
                count: 2,
                good: 1,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'blue',
        animation: {
            name: 'VER_JAUNE_DRAPEAUX',
            composition: '85A36236F376AD4295A99D82E65C6B2B',
            style: {
                width: '65vw',
                position: 'fixed',
                top: '53vh',
                right: '-6vw',
            },
        },
        image: imageStep6,
        questions: {
            easy: {
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'yellow',
        animation: {
            name: 'VER_MAUVE_FLEUR',
            composition: '59725A6178079E4B919F1029E9BD0B0F',
            style: {
                width: '68vw',
                position: 'fixed',
                top: '47vh',
                right: '3vw',
            },
        },
        image: imageStep7,
        questions: {
            easy: {
                count: 3,
                good: 3,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'orange',
        animation: {
            name: 'VER_BLEU_ANGE',
            composition: '7B8B0430C7949348B3A36E46FA270E27',
            style: {
                width: '81vw',
                position: 'fixed',
                top: '47vh',
                right: '-1vw',
            },
        },
        image: imageStep8,
        questions: {
            easy: {
                count: 3,
                good: 3,
            },
            hard: {
                count: 3,
                good: 3,
                roundImages: [imageRoundStep8],
            },
        },
    },
    {
        color: 'purple',
        animation: {
            name: 'VER_ORANGE_CANARD',
            composition: '49B5BE460FB88F4FBF02F531329EFD8F',
            style: {
                width: '75vw',
                position: 'fixed',
                top: '37vh',
                left: '-4vw',
            },
        },
        image: imageStep9,
        questions: {
            easy: {
                helper: true,
                count: 3,
                good: 3,
                roundImages: [imageRoundStep9],
            },
            hard: {
                helper: true,
                count: 3,
                good: 2,
                roundImages: [imageRoundStep9Hard],
            },
        },
    },
    {
        color: 'yellow',
        animation: {
            name: 'VER_BLEU_CASQUE',
            composition: 'F0D9919FE272F04C8CBEC1316CBB61E0',
            style: {
                width: '75vw',
                position: 'fixed',
                top: '25vh',
                right: '-5vw',
            },
        },
        image: imageStep10,
        questions: {
            easy: {
                helper: true,
                count: 3,
                good: 1,
            },
            hard: {
                helper: true,
                count: 3,
                good: 3,
                roundImages: [imageRoundStep10Hard],
            },
        },
    },
    {
        color: 'orange',
        animation: {
            name: 'VER_COLORS_COEUR',
            composition: 'E11E4DFA95E313489DF7CEDF876C87C3',
            style: {
                width: '65vw',
                position: 'fixed',
                bottom: '-1vh',
                right: '6vw',
            },
        },
        image: imageStep11,
        questions: {
            easy: {
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 1,
            },
        },
    },
    {
        color: 'blue',
        animation: {
            name: 'VER_JAUNE_FLEURS',
            composition: '1A8C1112EA1FD2499D1895CAA3A7BE53',
            style: {
                width: '70vw',
                position: 'fixed',
                top: '55vh',
                right: '-2vw',
            },
        },
        image: imageStep12,
        questions: {
            easy: {
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 3,
            },
        },
    },
    {
        color: 'yellow',
        animation: {
            name: 'VER_ORANGE_MATELOT',
            composition: '917790786C0578448B75E506ADE6824A',
            style: {
                width: '85vw',
                position: 'fixed',
                top: '60vh',
                left: '-9vw',
            },
        },
        image: imageStep13,
        questions: {
            easy: {
                roundImages: [imageRoundStep13],
                count: 3,
                good: 3,
            },
            hard: {
                count: 9,
                multi: true,
                goodMulti: [1, 3, 4, 9],
            },
        },
    },
    {
        color: 'purple',
        animation: {
            name: 'VER_BLEU_AQUAv2',
            composition: '336ACE2A9D39B6449B5006A8C8704AAF',
            style: {
                width: '140vw',
                position: 'fixed',
                top: '60vh',
                right: '-60vw',
            },
        },
        image: imageStep14,
        questions: {
            easy: {
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 2,
            },
        },
    },
    {
        color: 'blue',
        animation: {
            name: 'VER_MAUVE_CAR',
            composition: '159DDB89FE57E1479819D8E8D4A78485',
            style: {
                width: '85vw',
                position: 'fixed',
                top: '50vh',
                left: '-3vw',
            },
        },
        image: imageStep15,
        questions: {
            easy: {
                count: 3,
                good: 3,
            },
            hard: {
                count: 3,
                good: 3,
            },
        },
    },
    {
        color: 'orange',
        animation: {
            name: 'VER_COLORS_LUNETTE',
            composition: 'E13B03B852479E4F894452285790D796',
            style: {
                width: '75vw',
                position: 'fixed',
                bottom: '-1vh',
                left: '10vw',
            },
        },
        image: imageStep16,
        questions: {
            easy: {
                count: 3,
                good: 2,
            },
            hard: {
                count: 3,
                good: 3,
            },
        },
    },
];

export const useGetConfig = (questionId?: string): Config | undefined =>
    questionId && questionId !== ''
        ? configs[parseInt(questionId, 10) - 1]
        : undefined;
