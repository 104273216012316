import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AnimateCC from 'react-adobe-animate';

import { TopBar } from '../../components/TopBar';

import './index.scss';

type Props = {
    baseUrl?: string;
};

export const End: FunctionComponent<Props> = ({ baseUrl = '' }) => {
    const { t } = useTranslation();
    const { language } = useParams();
    return (
        <>
            <TopBar baseUrl={baseUrl} />
            <section className={`${language} container end with-top-bar`}>
                <div className="blue">
                    <p>{t('end.text1')}</p>
                    <h1>{t('end.thanks')}</h1>
                    <p>{t('end.text2')}</p>
                </div>
                <div className="orange">
                    <div className="animation">
                        <AnimateCC
                            animationName="VER_JAUNE_BYE"
                            composition="E040F1ADDB4460408A14117642E1251F"
                        />
                    </div>
                    <p className="more">{t('end.more')}:</p>
                    <a href={t('end.moreLink')}>{t('end.moreLinkText')}</a>
                </div>
                <div className="purple">
                    <div className="flex">
                        <div>
                            <div>
                                <h5>{t('end.direction')}</h5>
                                <span>Anne Vandenbulcke</span>
                                <span>Denis Laurent</span>
                            </div>
                            <div>
                                <h5>{t('end.conception')}</h5>
                                <span>Bruno Berghmans</span>
                                <span>Jean-Luc Petit</span>
                            </div>
                            <div>
                                <h5>{t('end.graph')}</h5>
                                <span>Clémence Cassells</span>
                                <span>
                                    <a href="http://www.designedbyjules.com">
                                        designedbyjules.com
                                    </a>
                                </span>
                            </div>
                            <div>
                                <h5>{t('end.dev')}</h5>
                                <span>Christophe Gérard</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5>{t('end.animation')}</h5>
                                <span>Fabian Urbain</span>
                            </div>
                            <div>
                                <h5>{t('end.photo')}</h5>
                                <span>Bruno Berghmans</span>
                                <span>Studio Fifty-Fifty</span>
                            </div>
                            <div>
                                <h5>{t('end.legal')}</h5>
                                <span>D2022/3285/27</span>
                            </div>
                        </div>
                    </div>
                    <p className="disclaimer">{t('end.disclaimer')}</p>
                </div>
            </section>
        </>
    );
};
