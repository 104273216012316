import i18next, { i18n as i18nInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languages } from './constants';

import nlTranslations from '../translations/nl.json';
import frTranslations from '../translations/fr.json';
import enTranslations from '../translations/en.json';

const createI18n = (language: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);

    i18n.use(LanguageDetector).init({
        resources: {
            ...nlTranslations,
            ...frTranslations,
            ...enTranslations,
        },
        lng: language,
        debug: true,
        fallbackLng: 'fr',
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            formatSeparator: ',',
        },
    });

    return i18n;
};

export const i18n = createI18n(languages.fr);
