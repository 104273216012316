import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useOuterClick } from '../../hooks/useOuterClick';

import './index.scss';

const languages: Array<string> = ['fr', 'en', 'nl'];

type Props = {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
};

export const LangSwitch: FunctionComponent<Props> = ({
    top = '10px',
    left = 'auto',
    right = '10px',
    bottom = 'auto',
}) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { language } = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleClick = (newLanguage: string) => {
        if (newLanguage === language) {
            setIsOpen(!isOpen);
        } else {
            navigate(pathname.replace(`${language}`, newLanguage), {
                replace: true,
            });
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.title = t('home.fullTitle');
    }, [language, t]);

    const innerRef = useOuterClick(() => {
        setIsOpen(false);
    });
    return (
        <nav
            className={`${language} lang-switch`}
            // @ts-ignore
            ref={innerRef}
            style={{ top, left, right, bottom }}
        >
            <ul className={`${isOpen ? 'open ' : ''} lang-container`}>
                {languages.map(lang => (
                    <li
                        key={lang}
                        onClick={() => handleClick(lang)}
                        className={language === lang ? 'selected ' : ''}
                    >
                        {lang}
                    </li>
                ))}
            </ul>
        </nav>
    );
};
