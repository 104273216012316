import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import { LangSwitch } from '../../components/LangSwitch';

import './index.scss';

import logos from '../../images/svg/cim_logo_bxlm.svg';

type Props = {
    baseUrl?: string;
};
export const Home: FunctionComponent<Props> = ({ baseUrl = '' }) => {
    const { t } = useTranslation();
    const { language } = useParams();
    return (
        <>
            <LangSwitch />
            <section className={`${language} container home`}>
                <div id="blue" className="worms" />
                <div id="yellow" className="worms" />
                <div id="orange" className="worms" />
                <div id="purple" className="worms" />
                <div className="content">
                    <a
                        id="logos"
                        href={t('home.logos')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={logos} alt="logos" />
                    </a>
                    <h1>
                        <span className="title1">{t('home.title1')} </span>
                        <span className="title-container">
                            <span>
                                <span className="title2">
                                    {' '}
                                    {t('home.title2')}
                                </span>
                                <span className="title3">
                                    {' '}
                                    {t('home.title3')}
                                </span>
                                <span className="title4">
                                    {' '}
                                    {t('home.title4')}
                                </span>
                            </span>
                        </span>
                        <span className="title5"> {t('home.title5')}</span>
                        <span className="mushrooms" />
                    </h1>
                    <div className="flex" id="start-link">
                        <Link
                            to={`/${baseUrl}/${language}/map`}
                            className="button"
                        >
                            {t('home.start-link')}
                        </Link>
                    </div>
                    {/* <div className="flex center link-container" id="start-link">
                        <a
                            id="link"
                            href={`http://${t('home.link')}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('home.link')}
                        </a>
                    </div> */}
                </div>
            </section>
        </>
    );
};
