import React, { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AnimateCC from 'react-adobe-animate';

import { Color } from '../Config';

type Props = {
    color: Color;
};

export const Animation: FunctionComponent<Props> = ({ color }) => {
    const { language, difficulty } = useParams();
    const langString = useMemo(() => language?.toLocaleUpperCase(), [language]);
    const difficultyString = useMemo(() => {
        if (difficulty === 'easy') return 'PETIT_FACILE';
        return 'GRD_DIFFICILE';
    }, [difficulty]);

    const animationString = `VER_COLOR${difficultyString}${langString}`;
    return (
        <div className="animation-container">
            <AnimateCC
                animationName={animationString}
                loop={false}
                composition={`3BCB74464BA3F549BF2FFA0FB0C7A4EF_${difficulty}_${language}_${color}`}
            />
        </div>
    );
};
