import React, { FunctionComponent, useEffect } from 'react';

import { Routes as RrdRoutes, Route, useParams } from 'react-router-dom';
import { i18n } from '../modules/i18n';

import { Home } from '../pages/Home/Index';
import { Map } from '../pages/Map';
import { Question } from '../pages/Questions';
import { End } from '../pages/End';

type Props = {
    baseUrl?: string;
};

export const Routes: FunctionComponent<Props> = ({ baseUrl = '' }) => {
    const { language } = useParams();
    useEffect(() => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
    }, [language]);
    return (
        <RrdRoutes>
            <Route path="/map" element={<Map baseUrl={baseUrl} />} />
            <Route path="/end" element={<End baseUrl={baseUrl} />} />
            <Route
                path="/:questionId/:difficulty/:step"
                element={<Question baseUrl={baseUrl} />}
            />
            <Route path="/" element={<Home baseUrl={baseUrl} />} />
        </RrdRoutes>
    );
};
