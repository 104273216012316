import React, {
    FunctionComponent,
    // useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import AnimateCC from 'react-adobe-animate';

import { Config } from '../Config';

type Props = {
    config: Config;
    baseUrl: string;
};

export const Step1: FunctionComponent<Props> = ({ config, baseUrl }) => {
    const { t } = useTranslation();
    const { language, questionId, difficulty } = useParams();
    return (
        <>
            <div className={config.color || ''}>
                <h1>
                    {t('questions.stage')}
                    <span>{questionId}</span>
                </h1>
                <p className="desc">{t(`questions.${questionId}.desc`)}</p>
                <Link
                    className={`button next ${config.color}`}
                    to={`/${baseUrl}/${language}/${questionId}/${difficulty}/step_2`}
                />
            </div>
            <div style={config.animation.style} className="animation-container">
                <AnimateCC
                    animationName={config.animation.name}
                    composition={config.animation.composition}
                />
            </div>
        </>
    );
};
