import { useRef, useEffect } from 'react';

// eslint-disable-next-line no-unused-vars
export const useOuterClick = (callback: (e: any) => void) => {
    const innerRef = useRef<HTMLElement>();
    // eslint-disable-next-line no-unused-vars
    const callbackRef = useRef<(e: any) => void>();

    // set current callback in ref, before second useEffect uses it
    useEffect(() => {
        // useEffect wrapper to be safe for concurrent mode
        callbackRef.current = callback;
    });

    useEffect(() => {
        // read most recent callback and innerRef dom node from refs
        const handleClick = (e: any) => {
            if (
                innerRef.current &&
                callbackRef.current &&
                !innerRef.current.contains(e.target)
            ) {
                callbackRef.current(e);
            }
        };
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []); // no need for callback + innerRef dep

    return innerRef; // return ref; client can omit `useRef`
};
