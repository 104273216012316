/* eslint-disable no-console */
import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import { Animation } from './Animation';

import { Config, configs } from '../Config';

type Props = {
    config: Config;
    baseUrl: string;
};

export const Step2: FunctionComponent<Props> = ({ config, baseUrl }) => {
    const { t } = useTranslation();
    const [selection, setSelection] = useState<number | undefined>();
    const [multiSelection, setMultiSelection] = useState<Array<number>>([]);
    const {
        questionId,
        difficulty,
        language,
    }: {
        questionId?: string;
        difficulty?: 'easy' | 'hard';
        language?: string;
    } = useParams();

    useEffect(() => {
        setSelection(undefined);
        setMultiSelection([]);
    }, [difficulty]);

    const nextStepUrl = useMemo(() => {
        const questionIndex = questionId && parseInt(questionId, 10);
        if (difficulty && questionIndex && configs.length > questionIndex) {
            return `/${baseUrl}/${language}/${
                questionIndex + 1
            }/${difficulty}/step_1`;
        }
        return `/${baseUrl}/${language}/end`;
    }, [baseUrl, difficulty, language, questionId]);

    const { good, count, multi, goodMulti } = difficulty
        ? config.questions[difficulty]
        : {
              good: undefined,
              count: undefined,
              multi: undefined,
              goodMulti: [],
          };
    const isGoodSelection: boolean = useMemo(() => {
        if (!multi) {
            return Boolean(selection && selection === good);
        }
        let isGood = true;
        multiSelection.forEach(ms => {
            if (!goodMulti?.includes(ms)) {
                isGood = false;
            }
        });
        return isGood;
    }, [good, goodMulti, multi, multiSelection, selection]);
    if (!difficulty) return null;
    const handleClick = (index: number) => {
        if (!multi) {
            setSelection(index + 1);
        } else {
            const newSelection = [...(multiSelection || [])];
            if (newSelection.indexOf(index + 1) !== -1) {
                newSelection.splice(newSelection.indexOf(index + 1), 1);
            } else {
                newSelection.push(index + 1);
            }
            setMultiSelection(newSelection);
        }
        const bottom = document
            .getElementById('bottom')
            ?.getBoundingClientRect().bottom;

        setTimeout(() => {
            window.scrollTo({
                behavior: 'smooth',
                top: bottom,
            });
        }, 100);
    };

    const displaySuccess =
        isGoodSelection &&
        (!multi || (multi && multiSelection.length === goodMulti?.length));
    const displayError =
        !isGoodSelection &&
        ((!multi && selection !== undefined) ||
            (multi && multiSelection.length > 0));
    return (
        <>
            <img src={config.image} alt="clue" className="question-image" />
            {config.questions[difficulty].helper && (
                <div className={`question-helper text-${config.color}`}>
                    {t(`questions.${questionId}.${difficulty}.helper`)}
                </div>
            )}
            <div className={`question-desc ${difficulty}`}>
                <span>
                    <Animation color={config.color} />
                </span>
                {t(`questions.${questionId}.${difficulty}.question`)}
            </div>
            <ul className="questions">
                {Array(count)
                    .fill('')
                    .map((_, index) => {
                        let isSelected;
                        if (!multi) {
                            isSelected = Boolean(
                                selection && selection - 1 === index,
                            );
                        } else {
                            isSelected = multiSelection.includes(index + 1);
                        }
                        return (
                            <li
                                key={index}
                                onClick={() => handleClick(index)}
                                className={`question-${config.color} ${
                                    isSelected ? 'selected' : ''
                                }`}
                            >
                                {t(
                                    `questions.${questionId}.${difficulty}.answer${
                                        index + 1
                                    }`,
                                )}
                            </li>
                        );
                    })}

                {displayError && (
                    <li className="error">{t('questions.bad')}</li>
                )}
                {displaySuccess && (
                    <li className="success">{t('questions.good')}</li>
                )}
            </ul>
            {config?.questions && config.questions[difficulty]?.roundImages && (
                <div className="center flex">
                    {config.questions[difficulty].roundImages?.map(
                        roundImage => (
                            <img
                                alt="help"
                                className="round-image"
                                src={roundImage}
                                key={roundImage}
                            />
                        ),
                    )}
                </div>
            )}
            <div className="flex center">
                {displaySuccess && (
                    <Link
                        className={`button next outline ${config.color}`}
                        to={nextStepUrl}
                    />
                )}
            </div>
            <span id="bottom" />
        </>
    );
};
